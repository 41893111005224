<template>
  <router-view />
</template>

<script>
import { format, getUnixTime } from 'date-fns'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '../config'

export default {
  name: 'PerRender',
  components: {
  },
  data() {
    return {}
  },
  computed: {
    reRenderComponentKey() {
      return this.$store.state[this.MODULE_NAME].reRenderComponentKey
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.$store.commit(`${this.MODULE_NAME}/SET_IS_LOADING`, true)
      this.$store
        .dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id)
        .then(res => {
          const { data } = res.data
          this.mapDataOnGetItem(data, this)
        })
        .finally(() => {
          this.$store.commit(`${this.MODULE_NAME}/SET_IS_LOADING`, false)
        })
    },

    mapDataOnGetItem(data, ctx) {
      const customerInformationData = ctx.mappingFields(Object.keys(this.customerInformation), data, {
        internal_account_notes: `${getValueFromGivenObjectByKey(data, 'supplier.customer_detail.comments', '')}`,
        external_order_reference: `${getValueFromGivenObjectByKey(data, 'external_order_reference', '')}`,
        payment_term_id: getValueFromGivenObjectByKey(data, 'payment_term', ''),
        attachments: data.attachments,
        customer_id: data.customer.id,
        billing_contact: data.billing_contact,
        billing_email: data.billing_contact && data.billing_contact.email,
        billing_phone: data.billing_contact && data.billing_contact.phone_four,
      })

      const dispatchAndReturnData = ctx.mappingFields(Object.keys(this.dispatchAndReturnFields), data, {
        dispatch_address_id: data?.dispatch_address && {
          id: data?.dispatch_address?.id,
          fullAddress: `${data?.dispatch_address?.street}, ${data?.dispatch_address?.city}, ${data?.dispatch_address?.state?.code}, ${data?.dispatch_address?.country?.three_letter}`,
        },
        dispatch_contact_id: data?.dispatch_contact && {
          id: data?.dispatch_contact?.id,
          fullName: `${data?.dispatch_contact?.first_name} ${data.dispatch_contact?.last_name}`,
        },
        dispatch_phone: data.dispatch_phone,
        dispatch_email: data.dispatch_email,
        return_address_id: data?.return_address && {
          id: data?.return_address?.id,
          fullAddress: `${data?.return_address?.street}, ${data?.return_address?.city}, ${data?.return_address?.state?.code}, ${data?.return_address?.country?.three_letter}`,
        },
        return_contact_is_different: data.return_contact_is_different,
        internal_shipping_notes: data.internal_shipping_notes,
        return_method: data?.return_method,
        order_routing_allow_dispatch_of_items_from_warehouse_of_origin:
        data.order_routing_allow_dispatch_of_items_from_warehouse_of_origin,
        order_routing_order_returns_to_another_warehouse:
        data.order_routing_order_returns_to_another_warehouse,
        dispatch_warehouse_id: data?.dispatch_warehouse,
        dispatch_method: data?.dispatch_method,
        return_warehouse_id: data?.return_warehouse,
        return_contact_id: data?.return_contact && {
          id: data?.return_contact?.id,
          full_name: data?.return_contact?.fullName,
        },
        return_phone: data?.return_contact?.phone_one,
        return_email: data?.return_contact?.email,
      })

      const eventInformationForm = ctx.mappingFields(Object.keys(this.periodFields), data, {
        period_rental_start_dispatch: data.rental_start && getUnixTime(new Date(data.rental_start)) * 1000,
        period_rental_end_receive: data.rental_end && getUnixTime(new Date(data.rental_end)) * 1000,
        period_rental_dispatch_start_time: data.rental_start && format(new Date(data.rental_start), 'HH:mm'),
        period_rental_receive_end_time: data.rental_end && format(new Date(data.rental_end), 'HH:mm'),
        event_reference: getValueFromGivenObjectByKey(data, 'event_reference', ''),
      })

      ctx.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, customerInformationData)
      ctx.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, dispatchAndReturnData)
      ctx.$store.commit(`${this.MODULE_NAME}/SET_EVENT_INFORMATION_FORM`, eventInformationForm)
      ctx.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, {
        products: data.products.map(i => ({
          ...i,
          rates: [{ rate: i.price }],
        })),
      })
      ctx.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, data.other_charges)
    },
  },
  setup() {
    const {
      quoteStatusBar, MODULE_NAME,
      customerInformation,
      dispatchAndReturnFields,
      periodFields,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      customerInformation,
      dispatchAndReturnFields,
      periodFields,
    }
  },
}
</script>
